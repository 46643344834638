export const productNameURLMapping = (productName) => {
  const map = new Map();
  map.set('Cuenta Libre', 'libre-account');
  map.set('Libre Account', 'libre-account');
  map.set('Cuenta Progresa', 'progresa-account');
  map.set('Progresa Account', 'progresa-account');
  map.set('Cuenta Ahorra', 'ahorra-account');
  map.set('Ahorra Account', 'ahorra-account');
  map.set('Cuenta ABC', 'abc-account');
  map.set('ABC Account', 'abc-account');
  map.set('Cuenta Ahorro Club', 'ahorro-club');
  map.set('Ahorro Club Account', 'ahorro-club');
  map.set('Certificado de Deposito', 'certificate-of-deposit');
  map.set('Certificate of Deposit', 'certificate-of-deposit');
  map.set('CD IRA', 'cd-ira');
  map.set('IRA Facil', 'ira-facil');
  map.set('IRA Fácil', 'ira-facil');
  map.set('Educational IRA', 'educational-ira');
  map.set('IRA Educativa', 'educational-ira');
  map.set('Annuity IRA', 'annuity-ira');
  map.set('Progresa Contigo', 'progresa-contigo');

  // VI Accounts
  map.set('Junior Account', 'junior-account');
  map.set('Easy Access Account', 'easy-access');
  map.set('Complete Checking Account', 'complete-checking');
  map.set('Oriental Savings Account', 'oriental-savings');
  map.set('Certificates of Deposits', 'certificates-of-deposits');

  // Commercial Accounts
  map.set('My Biz Account', 'my-biz-account');
  map.set('Ahorro Comercial', 'ahorro-comercial');
  map.set('AHORRO COMERCIAL', 'ahorro-comercial');
  map.set('CD Comercial', 'cd-comercial');
  map.set('Commercial CD', 'cd-comercial');
  map.set('SIN FINES DE LUCRO', 'sin-fines-de-lucro');
  map.set('NON-PROFIT', 'sin-fines-de-lucro');
  map.set('ESCROW ACCOUNT', 'escrow-account');

  map.set('Commercial Loan', 'Prestamo-Comercial');
  map.set('Prestamo Comercial', 'Prestamo-Comercial');
  map.set('Préstamo Comercial', 'Prestamo-Comercial');

  map.set('Commercial Credit Card', 'Tarjeta-de-Credito-Comercial');
  map.set('Tarjeta de Credito Comercial', 'Tarjeta-de-Credito-Comercial');
  map.set('Tarjeta de Crédito Comercial', 'Tarjeta-de-Credito-Comercial');

  map.set('Commercial Line of Credit', 'Linea-de-Credito-Comercial');
  map.set('Linea de Credito Comercial', 'Linea-de-Credito-Comercial');
  map.set('Línea de Crédito Comercial', 'Linea-de-Credito-Comercial');

  // dgi accounts
  map.set('DGI Balanced Fund IRA', 'dgi-balanced-fund-ira');
  map.set('DGI Contribution', 'dgi-contribution');
  map.set('DGI Aportaciones', 'dgi-contribution');

  // Consumer credit
  map.set('Consumer Credit', 'consumer-credit');

  return map.get(productName);
};
