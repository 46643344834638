import { isFirefox } from 'react-device-detect';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {
  AHORRO,
  COMMERCIAL_EN,
  COMMERCIAL_ES,
  ESCROWACCEN,
  ESNON_PROFIT,
  MYBIZ_EN,
  NONPROFIT,
} from '../../constants';

export const commonUtility = (value) => {
  if (value) {
    return value;
  }
  return null;
};

export const formattedDepositAmount = (depositAmount) => {
  if (depositAmount) {
    return depositAmount?.replace(/[^\w\s]/gi, '');
  }
  return null;
};

let updatedDepositAmount;
export const formattedDepositAmntForRegular = (depositAmount) => {
  if (depositAmount) {
    updatedDepositAmount = depositAmount?.replace(/[^\w\s]/gi, '');
    return `${updatedDepositAmount}.00`;
  }
  return null;
};

export const commonUtilityValueReturn = (condition, returnValue1, returnValue2) => {
  if (condition) {
    return returnValue1;
  }
  return returnValue2;
};

export const commonUtilityBooleanChk = (value) => {
  if (value) {
    return value;
  }
  return false;
};

export const checkFirefoxBrowser = (value1, value2) => {
  if (isFirefox) {
    return value1;
  }
  return value2;
};

export const getProductForDebitCard = (productName) => {
  let found = false;
  if (
    productName === 'Libre Account' ||
    productName === 'Cuenta Libre' ||
    productName === 'Progresa Account' ||
    productName === 'Cuenta Progresa' ||
    productName === 'Ahorra Account' ||
    productName === 'Cuenta Ahorra' ||
    productName === 'Junior Account' ||
    productName === 'Easy Access Account' ||
    productName === 'Complete Checking Account' ||
    productName === 'Oriental Savings Account' ||
    productName === 'Progresa Contigo'
  ) {
    found = true;
  }
  return found;
};

export const getProductForDepositeCard = (productName) => {
  let found = false;
  if (
    productName === 'Libre Account' ||
    productName === 'Cuenta Libre' ||
    productName === 'Progresa Account' ||
    productName === 'Cuenta Progresa'
  ) {
    found = true;
  }
  return found;
};

export const getProductForBusinessMapping = (productName) => {
  let found = false;
  if (
    productName === MYBIZ_EN ||
    productName === NONPROFIT ||
    productName === ESNON_PROFIT ||
    productName === ESCROWACCEN ||
    productName === AHORRO ||
    productName === COMMERCIAL_EN ||
    productName === COMMERCIAL_ES
  ) {
    found = true;
  }
  return found;
};
export const getProductForBusinessMappingCredit = (productName) => {
  let found = true;
  if (
    productName === MYBIZ_EN ||
    productName === ESCROWACCEN ||
    productName === AHORRO ||
    productName === NONPROFIT ||
    productName === ESNON_PROFIT ||
    productName === COMMERCIAL_EN ||
    productName === COMMERCIAL_ES
  ) {
    found = false;
  }
  return found;
};
export const getProductForEstatement = (productName) => {
  let isFound = false;
  if (productName === 'Libre Account' || productName === 'Cuenta Libre') {
    isFound = true;
  }
  return isFound;
};
export const getLang = (language, toLowerCase = false) => {
  let lang = 'En';
  if (language === 'ES') {
    lang = 'Es';
  }

  if (toLowerCase) return lang.toLowerCase();

  return lang;
};
function cryptoRandom(len) {
  // eslint-disable-next-line global-require
  const crypto = require('crypto');
  const randomBytes = crypto.randomBytes(32);
  const randomNbr = `${randomBytes.toString('hex').substring(0, len)}`;
  return randomNbr;
}
export const encryptData = (str, code) => {
  const prefix = cryptoRandom(16);
  const ivdata = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
  const key = CryptoJS.enc.Utf8.parse(code);

  return AES.encrypt(prefix + str, key, {
    iv: ivdata,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

// eslint-disable-next-line arrow-body-style
export const generateKey = () => {
  return cryptoRandom(32);
};
export const getNameInUpperCase = (name) => {
  if (name?.includes(' ')) {
    const words = name.toLowerCase().split(' ');
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};
