/* eslint-disable max-lines */
import axios from 'axios';
import { productTypeMapping } from './productTypeMapping';
import { productMapping } from './productMapping';
import {
  buildConsentInfo,
  createDocumentProofsArray,
  createFormData,
  createSourceInformation,
  formatSSNToNumbers,
  getQuestionAndAnswers,
  returnOccupationInEN,
} from './formServiceUtils';
import { businessMapping } from './businessMapping';
import { dgiMapping } from './dgiMapping';
import { dgiContMapping } from './dgiContMapping';

import {
  commonUtility,
  formattedDepositAmntForRegular,
  getProductForDebitCard,
  getProductForDepositeCard,
} from '../components/LeadGeneration/UtilityFunctions';
import { DIRECT_DEPOSITE_RADIO_TEXT, EN, ES } from '../constants';

export const getProductQuestionTranslation = async (
  accessToken,
  language,
  customerType,
  flowTypeETB
) => {
  let productQuestionTranslation = {};
  const lang = language?.toLowerCase();
  try {
    if (flowTypeETB === 'EtbFlow') {
      productQuestionTranslation = await axios.get(
        `${process.env.REACT_APP_PRODUCT_QUESTIONS_TRANSLATION_URL_EXPRESS}/${lang}_application_rules.json`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } else {
      productQuestionTranslation = await axios.get(
        `${process.env.REACT_APP_PRODUCT_QUESTIONS_TRANSLATION_URL}/${lang}_application_rules.json`,
        { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } }
      );
    }
  } catch (e) {
    return productQuestionTranslation;
  }
  return productQuestionTranslation;
};

export const getAddressLookupData = async (country, word, accessToken) => {
  let addressLookupResponse = {};
  try {
    addressLookupResponse = await axios.get(
      `${process.env.REACT_APP_ADDRESS_LOOKUP_URL}?ff=${word}&country=${country}&maxrecords=7`,
      { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } }
    );
  } catch (e) {
    return addressLookupResponse;
  }
  return addressLookupResponse;
};

export const getQRDetailsLookupData = async (data, accessToken) => {
  const payload = {
    productType: `${data.productType} Account`,
    productName: data.productName,
    emailId: data.email,
    salesAdvisor: data.salesAdvisor,
    language: data.language === 'English' ? EN : ES,
    organization: data.organization,
  };

  let qrDetailsLookupResponse = null;
  try {
    qrDetailsLookupResponse = await axios.post(
      process.env.REACT_APP_QR_DETAILS_LOOKUP_URL,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      }
    );
  } catch (e) {
    return e.qrDetailsLookupResponse;
  }
  return qrDetailsLookupResponse;
};
export const getProductQuestions = async (
  data,
  ruleType,
  accessToken,
  sessionCallFlag,
  setSessionCallFlag
) => {
  const formData = createFormData([ruleType], data);
  let response = null;
  try {
    if (data.customerType === 'ETB' && data.flowTypeETB === 'EtbFlow') {
      if (sessionCallFlag) {
        response = await axios.post(process.env.REACT_APP_AGE_RULE_URL_EXPRESS, formData, {
          headers: {
            'Application-Id': data.customerAppId,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        });
        if (response && response.status === 200) {
          setSessionCallFlag(false);
        }
      }
    } else {
      response = await axios.post(process.env.REACT_APP_AGE_RULE_URL, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
    }
  } catch (e) {
    return e.response;
  }
  return response;
};
export const saveCommercialConsent = async (appId, token) => {
  let result = null;
  const body = {
    Event: 'CaptureBankerInfo',
    Application: {
      ApplicationID: appId,
    },
  };
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return null;
  }
  return result;
};
const commonTernary = (condition, result1, result2) => {
  if (condition) {
    return result1;
  }
  return result2;
};
export const saveConsent = async (data, accessToken, customerType, flowTypeETB, customerAppId) => {
  const consentContent = buildConsentInfo(data);
  const formData = {
    Event:
      data.flowType === 'invitation-form'
        ? 'CaptureBusinessConsents'
        : commonTernary(
            businessMapping(data.productName),
            'CaptureBusinessConsents',
            'CaptureConsents'
          ),
    Application: {
      ApplicationID: data.applicationId ? data.applicationId : customerAppId,
      ...(data.flowType === 'invitation-form' && {
        ApplicantID: data.flowType === 'invitation-form' ? data.applicantId : null,
      }),

      TemplateVersion: '2021-08-05',
      ...(data.flowType === 'invitation-form' && {
        Products: [
          {
            ProductType: 'CommercialInvitation',
            ProductName: 'SecondaryApplicant',
          },
        ],
      }),

      Source: createSourceInformation(data),
      Applicants: [
        {
          ...(data.flowType === 'invitation-form' && {
            ApplicantID: data.flowType === 'invitation-form' ? data.applicantId : null,
          }),

          ApplicantCategory: businessMapping(data.productName) ? 'DigitalApplicant' : 'Primary',
          ApplicantID:
            data.flowType === 'invitation-form'
              ? data.applicantId
              : commonTernary(businessMapping(data.productName), data.applicantId, ''),

          Consents: consentContent,
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    // need to keep ETB logic here?
    if (customerType === 'ETB' && flowTypeETB === 'EtbFlow') {
      result = await axios.post(
        process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL_EXPRESS,
        formData,
        {
          headers: {
            'Application-Id': customerAppId,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        }
      );
    } else {
      result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
    }
  } catch (error) {
    return null;
  }
  return result;
};

let setProduct;
const nameProduct = (data) => {
  if (dgiMapping(data.productName)) {
    setProduct = dgiMapping(data.productName);
  } else if (dgiContMapping(data.productName)) {
    setProduct = dgiContMapping(data.productName);
  } else {
    setProduct = productMapping(data.productName);
  }
  return setProduct;
};

export const saveContactInformation = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureContactInfo',
    Application: {
      ApplicationID: data.applicationId,
      TemplateVersion: '2021-08-05',
      Source: createSourceInformation(data),
      Products: [
        {
          ProductType: productTypeMapping(data.productType),
          ProductName: nameProduct(data),
        },
      ],
      Applicants: [
        {
          ApplicantCategory: 'Primary',
          CustName: {
            FrstName: data.firstName,
            LstName: data.lastName,
          },
          Contacts: [
            {
              ContactType: 'email',
              ContactValue: data.email,
              Validation: {
                ValidatedBy: data.emailVerificationResponse
                  ? data.emailVerificationResponse?.validatedBy
                  : null,
                Status: data.emailVerificationResponse
                  ? data.emailVerificationResponse?.status
                  : null,
                ResultCodes: data.emailVerificationResponse
                  ? data.emailVerificationResponse.resultCode
                  : null,
                Message: data.emailVerificationResponse
                  ? data.emailVerificationResponse.message
                  : null,
                Timestamp: data.emailVerificationResponse
                  ? data.emailVerificationResponse.timestamp
                  : null,
              },
            },
            {
              ContactType: 'mobile',
              ContactValue:
                data.location !== 'VI' ? `(${data.areaCode}) ${data.phone}` : data.phone,
              PrefferedContactTime: data.preferredContactTime ? data.preferredContactTime : null,
              Validation: {
                ValidatedBy: data.phoneValidationResponse
                  ? data.phoneValidationResponse?.validatedBy
                  : null,
                Status: data.phoneValidationResponse ? data.phoneValidationResponse.status : null,
                ResultCodes: data.phoneValidationResponse
                  ? data.phoneValidationResponse.resultCodes
                  : null,
                Message: data.phoneValidationResponse ? data.phoneValidationResponse.message : null,
                Timestamp: data.phoneValidationResponse
                  ? data.phoneValidationResponse.timestamp
                  : null,
              },
            },
          ],
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
let altPhoneVal;
const altPhone = (data) => {
  if (productMapping(data?.productName) === 'Progresa Contigo') {
    if (data?.phone) {
      altPhoneVal = `(${data.areaCode}) ${data.phone}`;
    }
  } else if (data?.altPhone) {
    if (data.location !== 'VI') {
      altPhoneVal = `(${data.altAreaCode}) ${data.altPhone}`;
    } else {
      altPhoneVal = data.altPhone;
    }
  } else {
    altPhoneVal = null;
  }
  return altPhoneVal;
};
export const savePersonalInformation = async (data, accessToken) => {
  const vouchedData =
    data.vouchedIdData && Object.keys(data.vouchedIdData).length > 0 ? data.vouchedIdData : null;

  const formData = {
    Event: 'CapturePrimaryIdInfo',
    Application: {
      ApplicationID: data.applicationId,
      TemplateVersion: '2021-08-05',
      Source: createSourceInformation(data),
      Products: [
        {
          ProductType: productTypeMapping(data.productType),
          ProductName: nameProduct(data),
        },
      ],

      Applicants: [
        {
          ApplicantCategory: 'Primary',
          CustTaxId: formatSSNToNumbers(data.ssn),
          DateOfBirth: data.dob,
          Gender: data.gender,
          ...(dgiMapping(data.productName) ? { MaritalStatus: data.maritalStatus } : {}),
          CustName: {
            FrstName: data.firstName,
            MdleName: commonUtility(data.middleName),
            LstName: data.lastName,
            SecondLstName: commonUtility(data.secondLastName),
          },
          Contacts: [
            {
              ContactType: 'email',
              ContactValue: data.email,
              Validation: {
                ValidatedBy: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.validatedBy,
                  null
                ),
                Status: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.status,
                  null
                ),
                ResultCodes: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.resultCode,
                  null
                ),
                Message: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.message,
                  null
                ),
                Timestamp: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.timestamp,
                  null
                ),
              },
            },
            {
              ContactType: commonTernary(
                productMapping(data.productName) === 'Progresa Contigo',
                'mobile',
                'alternateMobile'
              ),
              ContactValue: altPhone(data),
              Validation: {
                ValidatedBy: commonTernary(
                  data.altPhoneValidationResponse,
                  data.altPhoneValidationResponse?.validatedBy,
                  null
                ),
                Status: commonTernary(
                  data.altPhoneValidationResponse,
                  data.altPhoneValidationResponse?.status,
                  null
                ),
                ResultCodes: commonTernary(
                  data.altPhoneValidationResponse,
                  data.altPhoneValidationResponse?.resultCodes,
                  null
                ),
                Message: commonTernary(
                  data.altPhoneValidationResponse,
                  data.altPhoneValidationResponse?.message,
                  null
                ),
                Timestamp: commonTernary(
                  data.altPhoneValidationResponse,
                  data.altPhoneValidationResponse?.timestamp,
                  null
                ),
              },
            },
          ],
          Identifications: [
            {
              IdenDOB: data.dob,
              IdenType: vouchedData && commonUtility(vouchedData.type),
              IdNumber: vouchedData && commonUtility(vouchedData.id),
              StartDate: vouchedData && commonUtility(vouchedData.issueDate),
              ExpiryDate: vouchedData && commonUtility(vouchedData.expireDate),
              IssuingCountry: vouchedData && commonUtility(vouchedData.country),
              IdenGender:
                vouchedData && vouchedData.gender && commonUtility(vouchedData.gender.gender),
            },
          ],
          AdditionalInfo: {
            Vouched: {
              JobId: commonUtility(data.vouchedJobId),
              Token: commonUtility(data.vouchedToken),
              ...(data.vouchedAddressVerified && {
                Address: {
                  LineOne: commonUtility(data.addrLineOne),
                  LineTwo: commonUtility(data.addrLineTwo),
                  AddrCity: data.city,
                  AddrState: data.state,
                  AddrCountry: data.country,
                  ZipCode: data.zipCode,
                },
              }),
            },
          },
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

let status;
let valid;
const validatedBy = (data) => {
  if (data.sameMailingAddress) {
    if (data.vouchedAddrEdited || data.unverifiedIdAddressVouched) {
      valid = null;
    } else {
      valid = 'vouched';
    }
  } else {
    valid = null;
  }
  return valid;
};
const mailingStatus = (data) => {
  if (data.sameMailingAddress) {
    if (data.vouchedAddrEdited || data.unverifiedIdAddressVouched) {
      status = null;
    } else {
      status = data.vouchedStatus;
    }
  } else {
    status = null;
  }
  return status;
};
const mailingTimeStamp = (data) => {
  if (data.sameMailingAddress) {
    if (data.vouchedAddrEdited || data.unverifiedIdAddressVouched) {
      status = null;
    } else {
      status = data.vouchedTimestamp;
    }
  } else {
    status = null;
  }
  return status;
};

export const saveAddressInformation = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureAddressInfo',
    Application: {
      ApplicationID: data.applicationId,
      TemplateVersion: '2021-08-05',
      Source: createSourceInformation(data),
      Applicants: [
        {
          Addresses: [
            {
              AddrType: 'home',
              LineOne: data.address1,
              LineTwo: commonUtility(data.address2),
              AddrCity: data.city,
              AddrState: data.state,
              AddrCountry: data.country,
              ZipCode: data.zipCode,
              ...(data.utilityBill && {
                BillType: data.utilityBill,
              }),

              Validation: {
                ValidatedBy:
                  data.vouchedAddrEdited || data.unverifiedIdAddressVouched ? null : 'Vouched',
                Status:
                  data.vouchedAddrEdited || data.unverifiedIdAddressVouched
                    ? null
                    : data.vouchedStatus,
                ResultCodes: null,
                Message: null,
                Timestamp:
                  data.vouchedAddrEdited || data.unverifiedIdAddressVouched
                    ? null
                    : data.vouchedTimestamp,
              },
              OCRVerifiedFlag: data.ocrAddressVerified ? 'true' : 'false',
              OCRAddressEditFlag: data.ocrAddressEdit ? 'true' : 'false',
              OcrExceptions: data.ocrErrors,
              // ...(data.newAddressEdit ||
              // data.ocrAddressVerified ||
              // data.vouchedAddressVerified === false
              ...(data.utilityBill
                ? // data.retainUtilityBill === true
                  {
                    Documents: createDocumentProofsArray(
                      data.homeAddressUploadDocumentId,
                      'Home Address Proof'
                    ),
                  }
                : {}),
            },
            {
              AddrType: 'mailing',
              LineOne: data.permanentAddress1,
              LineTwo: commonUtility(data.permanentAddress2),
              AddrCity: data.permanentCity,
              AddrState: data.permanentState,
              AddrCountry: data.permanentCountry,
              ZipCode: data.permanentZipCode,
              Validation: {
                ValidatedBy: validatedBy(data),
                Status: mailingStatus(data),
                ResultCodes: null,
                Message: null,
                Timestamp: mailingTimeStamp(data),
              },
            },
          ],
          AdditionalInfo: {
            IsMailHomeAddrSame: data.sameMailingAddress,
            IsIdAddressEdited:
              data.vouchedJobId && data.vouchedAddressVerified
                ? data.vouchedAddrEdited && data.vouchedJobId && data.vouchedAddressVerified
                : data?.adrrFlag,
            UserOptionCurrentAddressFlag: data.vouchedCurrentAddressFlag ? 'true' : 'false',
            UserOptionNewAddressFlag: data.vouchedNewAddressFlag ? 'true' : 'false',
          },
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveComplianceInformation = async (data, accessToken) => {
  const questions = data.complianceData?.questions ? data.complianceData.questions : {};
  const formAnswer = {};
  const { itemsAnswer } = data;
  const { act22DocID } = data;
  const questionAndAnswers = getQuestionAndAnswers(questions, itemsAnswer, formAnswer, act22DocID);
  const formData = {
    Event: 'CaptureComplianceInfo',
    Application: {
      ApplicationID: data.applicationId,
      TemplateVersion: '2021-08-05',
      Source: createSourceInformation(data),
      Applicants: [
        {
          ApplicantCategory: 'Primary',
          ComplianceQuestions: questionAndAnswers,
          EmploymentInformation: {
            EmploymentStatus: data.employmentInformation?.employmentStatus
              ? data.employmentInformation.employmentStatus
              : null,
            Employer: data.employmentInformation?.employer
              ? data.employmentInformation.employer
              : null,
            Occupation: data.employmentInformation?.occupation
              ? returnOccupationInEN(data.employmentInformation.occupation)
              : null,
            AnnualIncome: data.employmentInformation?.annualIncome
              ? data.employmentInformation.annualIncome
              : null,
            Reason: data.employmentInformation?.reason ? data.employmentInformation.reason : null,
            SchoolName: data.employmentInformation?.schoolName
              ? data.employmentInformation.schoolName
              : null,
            SourceOfCurrentIncome: data.employmentInformation?.sourceOfCurrentIncome
              ? data.employmentInformation.sourceOfCurrentIncome
              : null,
            PreviousEmployer: data.employmentInformation?.previousEmployer
              ? data.employmentInformation.previousEmployer
              : null,
          },
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveProductInformation = async (data, accessToken) => {
  const questions = data.productData.questions ? data.productData?.questions : {};
  const { formAnswer, itemsAnswer } = data;
  const act22DocID = null;
  const questionAndAnswers = getQuestionAndAnswers(
    questions,
    itemsAnswer,
    formAnswer,
    act22DocID,
    data
  );
  const formData = {
    Event: 'CaptureProductInfo',
    Application: {
      ApplicationID: data.applicationId ? data.applicationId : data.customerAppId,
      TemplateVersion: '2021-08-05',
      Source: createSourceInformation(data),
      Products: [
        {
          ProductType: productTypeMapping(data.productType),
          ProductName: productMapping(data.productName) || businessMapping(data.productName),
          ...(getProductForDebitCard(data.productName)
            ? { IsDebitCardRequested: data.debitCardFlag === true ? 'true' : 'false' }
            : {}),
          ...(getProductForDepositeCard(data.productName)
            ? {
                IsDirectDeposited:
                  data.depositeCardFlag === DIRECT_DEPOSITE_RADIO_TEXT ? 'true' : 'false',
              }
            : {}),
          Questions: questionAndAnswers,
          ...(data.productName === 'Progresa Contigo'
            ? {
                SalesAdvisor: data.salesAdvisor || null,
              }
            : {}),
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    if (data.customerType === 'ETB' && data.flowTypeETB === 'EtbFlow') {
      result = await axios.post(
        process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL_EXPRESS,
        formData,
        {
          headers: {
            'Application-Id': data.customerAppId,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json; charset=UTF-8',
          },
        }
      );
    } else {
      result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
    }
  } catch (error) {
    return error.response;
  }
  return result;
};

export const fundingAccount = (data) => {
  let account;
  if (data.accountTransferOrCheck && data.orientalAccount) {
    account = 'OrientalAccount';
  } else if (data.accountTransferOrCheck && data.anotherBankAccount) {
    account = 'AnotherBankAccount';
  } else {
    account = null;
  }
  return account;
};
export const bankName = (data) => {
  let bankType;
  if (data.bank) {
    if (data.bank === 'Other') {
      bankType = data.otherBank;
    } else {
      bankType = data.bank;
    }
  } else {
    bankType = null;
  }
  return bankType;
};

export const saveFundingInfo = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureFundingInfo',
    Application: {
      ApplicationID: data.applicationId,
      TemplateVersion: '2021-08-05',
      Source: createSourceInformation(data),
      Products: [
        {
          ProductType: productTypeMapping(data.productType),
          ProductName: productMapping(data.productName),
          FundingInfo: {
            // "FundingOption": data.debitCard ? "DebitCard" : data.accountTransferOrCheck ? "AccountTransferOrCheck" : null,
            FundingType: fundingAccount(data),
            BankName: bankName(data),
            AccountNumber: data.accountNumber ? data.accountNumber : null,
            AccountType: data.typeOfAccount ? data.typeOfAccount : null,
            TransitNumber: data.transitNumber ? data.transitNumber : null,
            DepositAmount: formattedDepositAmntForRegular(data.depositAmount),
            IsAccountBelongsToYou:
              fundingAccount(data) === 'AnotherBankAccount'
                ? commonUtility(data.isAccountBelongsToYou)
                : null, // "CardNumber": data.cardNumber ? data.cardNumber : null,
            // "CardExpiryDate": data.debitCard && data.expiryMonth !== "MMM" && data.expiryYear !== "YYYY" ? format(new Date(data.expiryMonth + "/" + data.expiryYear), "MM/yyyy") : null,
            Documents: createDocumentProofsArray(data.statementDocumentId, 'Check/Bank Statement'),
          },
        },
      ],
      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const getAccountValidation = async (accountNumber, accessToken) => {
  const payload = {
    AcctNbr: accountNumber,
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_ACCOUNT_VALIDATION, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
export const getCustomerValidation = async (ssn, accessToken) => {
  const payload = {
    TaxID: ssn,
    AccountCheckFlag: 'false',
    GenerateApplicationId: 'true',
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_CUSTOMER_VALIDATION, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
export const getOcrAddressValidation = async (data, accessToken) => {
  const payload = {
    documentId: data.docId,
    format: data.docFormat,
    billType: data.billType,
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_OCR_EXTRACT_ADDRESS, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveContinueInformation = async (data, accessToken) => {
  // const formData =
  // Event: 'CaptureSaveContinueContactInfo',
  const formData = {
    TemplateVersion: '2023-07-28',
    ApplicationID: data.applicationId,
    FrstName: data.firstName,
    LstName: data.lastName,
    language: data.language,
    location: data.location,
    dob: data.dob,
    email: data.email,
    taxID: formatSSNToNumbers(data.ssn),
  };

  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_CONTINUE, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const buildSession = async (applicationId, accessToken) => {
  let sessionResponse = {};
  try {
    sessionResponse = await axios.get(`${process.env.REACT_APP_CUSTOMER_BUILD_SESSION}`, {
      // eslint-disable-next-line prettier/prettier
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Application-Id': `${applicationId}`,
      },
    });
  } catch (e) {
    return sessionResponse;
  }
  return sessionResponse;
};
export const fetchVouchedAddress = async (jobId, accessToken) => {
  let result = null;

  try {
    result = await axios.get(
      `${process.env.REACT_APP_GET_VOUCHED_DS_INFO}?jobId=${jobId}&source=salesUi`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    return error.response;
  }
  return result;
};
