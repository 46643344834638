import { makeStyles } from '@material-ui/core';
import { COLOR1, COLOR4, RADIO_COLOR } from '../../constants';

export const SubmissionPageStyles = () =>
  makeStyles(
    (theme) => ({
      approveThank: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: '37px',
        textAlign: 'center',
        marginTop: '21px',
        color: '#000000',
      },
      thankyouButtonApprove: {
        background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
        color: COLOR4,
        display: 'flex',
        justifyContent: 'center',
        letterSpacing: '0.3px',
        font: 'normal normal normal 16px/18px Arial',
        width: '250px',
        height: '45px',
        borderRadius: '25px',
        marginTop: '39px',
        marginBottom: '50px',
        opacity: 1,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        [theme.breakpoints.down('650')]: {
          marginTop: '50px',
          marginBottom: '50px',
        },
        [theme.breakpoints.down('600')]: {
          padding: '0px 33px',
        },
        [theme.breakpoints.down('320')]: {
          width: 'fit-content',
        },
      },
      secondText: {
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        color: '#000000',
      },
      displayInMiddle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '23px',
      },
      container: {
        height: 'auto',
        padding: '20px 30px',
        background: '#ffffff',
        [theme.breakpoints.down('600')]: {
          margin: '0px',
          padding: '0px',
        },
      },
      containerBtn: {
        display: 'flex',
        justifyContent: 'center',
      },
      thankyouButton: {
        background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
        color: COLOR4,
        display: 'flex',
        justifyContent: 'center',
        letterSpacing: '0.3px',
        font: 'normal normal normal 16px/18px Arial',
        width: '250px',
        height: '45px',
        borderRadius: '25px',
        marginTop: '62px',
        marginBottom: '50px',
        opacity: 1,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        [theme.breakpoints.down('650')]: {
          marginTop: '50px',
          marginBottom: '50px',
        },
        [theme.breakpoints.down('600')]: {
          padding: '0px 33px',
        },
        [theme.breakpoints.down('320')]: {
          width: 'fit-content',
        },
      },

      thankyouTitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '64px',
        lineHeight: '76px',

        textAlign: 'center',

        color: '#000000',
      },
      thankyouSUBTitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#000000',
        marginTop: '21px',
        [theme.breakpoints.down('600')]: {
          padding: '0px 27px',
        },
        textAlign: 'center',
      },
      thankYouContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '23px',
        marginTop: '147px',
        [theme.breakpoints.down('600')]: {
          marginTop: '0px',
          marginBottom: '0px',
          paddingTop: '75px',
          paddingBottom: '23px',
        },
      },
      returnToOriental: {
        background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
        color: COLOR4,
        letterSpacing: '0.3px',
        font: 'normal normal normal 16px/18px Arial',
        width: '250px',
        height: '45px',
        borderRadius: '25px',
        marginTop: '50px',
        marginBottom: '50px',
        marginLeft: '41px',
        opacity: 1,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        [theme.breakpoints.down('650')]: {
          marginTop: '50px',
          marginBottom: '50px',
          marginLeft: '0',
        },
        [theme.breakpoints.down('320')]: {
          width: 'fit-content',
        },
      },

      centerBtn: {
        [theme.breakpoints.down('650')]: {
          display: 'flex',
          justifyContent: 'center',
        },
      },

      returnToOrientalInError: {
        background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
        color: COLOR4,
        letterSpacing: '0.3px',
        font: 'normal normal normal 16px/18px Arial',
        width: '250px',
        height: '45px',
        borderRadius: '25px',
        marginTop: '50px',
        marginBottom: '50px',
        opacity: 1,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        [theme.breakpoints.down('650')]: {
          marginTop: '30px',
          marginBottom: '30px',
        },
        [theme.breakpoints.down('320')]: {
          width: 'fit-content',
        },
      },

      success: {
        width: '597px',
        height: '124px',
        [theme.breakpoints.down('700')]: {
          width: '90%',
          height: '100%',
          margin: '0 20px',
        },
        [theme.breakpoints.down('350')]: {
          width: '300px',
          height: '100%',
          margin: '0 10px',
        },
      },

      error: {
        width: '100px',
        height: '100px',
      },

      imgGrid: {
        margin: '30px 0',
        [theme.breakpoints.down('650')]: {
          margin: '50px 0',
        },
      },

      title: {
        margin: '21px 30px 22px 41px',
        color: COLOR1,
        font: 'normal normal bold 26px/29px Arial',
        letterSpacing: '0.18px',
        opacity: 1,
        [theme.breakpoints.down('650')]: {
          margin: '10px 21px',
          font: 'normal normal bold 16px/18px Arial',
        },
      },

      errorTitle: {
        margin: '21px 30px 22px 41px',
        color: COLOR1,
        font: 'normal normal bold 26px/29px Arial',
        letterSpacing: '0.18px',
        opacity: 1,
        textAlign: 'center',
        maxWidth: '600px',
        [theme.breakpoints.down('650')]: {
          margin: '10px 21px',
          font: 'normal normal bold 21px/25px Arial',
        },
      },

      subTitle: {
        margin: '0 41px 0 41px',
        color: COLOR1,
        font: 'normal normal normal 20px/23px Arial',
        letterSpacing: '0.14px',
        opacity: 1,
        [theme.breakpoints.down('650')]: {
          margin: '10px 21px',
          font: 'normal normal normal 16px/18px Arial',
        },
      },
    }),
    { index: 1 }
  );
