import axios from 'axios';
import { CONTENT_TYPE } from '../constants';

export const getAccessToken = async () => {
  const tokenResponse = await axios.post(
    process.env.REACT_APP_TOKEN_SERVICE_URL,
    { clientId: process.env.REACT_APP_CLIENT_ID },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return tokenResponse?.data?.token?.split(' ')[1];
};
export const getAccessTokenForOtp = async () => {
  let token = null;
  const tokenResponse = await axios.post(
    process.env.REACT_APP_SSP_TOKEN_SERVICE_URL,
    { clientId: process.env.REACT_APP_CLIENT_ID_OTP },
    {
      headers: {
        'Content-Type': CONTENT_TYPE,
      },
    }
  );
  if (tokenResponse && tokenResponse.data) {
    token = tokenResponse.data.access_token;
  }
  return token;
};

export const sspLogoutCall = async (token, customerNumber, language, uuid) => {
  let response = null;
  try {
    response = await axios.post(
      process.env.REACT_APP_EXPRESS_SSP_SESSION_LOGOUT,
      {
        customerNumber,
        language: language === 'EN' ? 'eng' : 'esp',
        uuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (e) {
    return e.response;
  }
  return response;
};
